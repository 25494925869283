<template>
  <div class="page brochure">
    <SectionSelector v-for="(section, key) in sections" :data="section.data" :section="section.type" :order-no="section.order_no" :key="key" />
  </div>
</template>

<script>
import SectionSelector from "./SectionSelector";
const config  = require("../../config").default;

export default {
  name: "BrochurePage",
  metaInfo() {
    return {
      title: this.meta.baseTitle,
      titleTemplate: `%s - ${this.meta.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.meta.description,
        }
      ]
    };
  },
  components: {
    SectionSelector
  },
  computed: {
    meta() {
      return this.$route.meta.page.meta;
    },
    sectionsData() {
      return this.$route.meta.page.sections;
    }
  },
  data() {
    return {
      sections: []
    }
  },
  created() {
    this.sections = this.sectionsData.sort((a, b) => {
      if ( a.order_no < b.order_no ){
        return -1;
      }
      if ( a.order_no > b.order_no ){
        return 1;
      }
      return 0;
    });
  },
  beforeMount() {
    if (this.$store.state.base.documentIsReady) {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE), false);
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

</style>